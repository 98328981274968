// Bootstrap Imports
import 'popper.js';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import './animations';

/*******************************************
 *  Helper Functions
 *******************************************/

// Handles flash of unstyled content.
function fouc() {
	$('html').removeClass('no-js').addClass('js');
}

// Add all scroll functions here
function scroll() {
	mainNavigationScroll();
}

// Optimize the scroll events.
function optimizeScroll() {
	let scrollWaiting = false,
		endScrollHandle;
	$(window).scroll(function () {
		if (scrollWaiting) {
			return;
		}
		scrollWaiting = true;

		// clear previous scheduled endScrollHandle
		clearTimeout(endScrollHandle);

		scroll();

		setTimeout(function () {
			scrollWaiting = false;
		}, 100);

		// schedule an extra execution of scroll() after 200ms
		// in case the scrolling stops in next 100ms
		endScrollHandle = setTimeout(function () {
			scroll();
		}, 200);
	});
}

function mainNavigationScroll() {
	const scrollTop = $(window).scrollTop();
	const $siteHeader = $('.site-header');
	const $siteHeaderSpacer = $('.site-header__spacer');

	if (scrollTop > 0) {
		$siteHeader.removeClass('top');
		$siteHeaderSpacer.removeClass('top');
	} else {
		$siteHeader.addClass('top');
		$siteHeaderSpacer.addClass('top');
	}
}

// function anchorSmoothScrolling() {
// 	const spacing = 86;
// 	$('a[href*=\\#]:not([href=\\#])').click(function () {
// 		if (
// 			location.pathname.replace(/^\//, '') ===
// 				this.pathname.replace(/^\//, '') ||
// 			location.hostname === this.hostname
// 		) {
// 			let target = $(this.hash);
// 			target = target.length
// 				? target
// 				: $('[name=' + this.hash.slice(1) + ']');
// 			if (target.length) {
// 				$('html,body').animate(
// 					{
// 						scrollTop: target.offset().top - spacing,
// 					},
// 					1000
// 				);
// 				return false;
// 			}
// 		}
// 	});
//
// 	if (window.location.hash) {
// 		$('html,body').animate(
// 			{
// 				scrollTop: $(window.location.hash).offset().top - spacing,
// 			},
// 			1000
// 		);
// 	}
// }

/**
 * Use Bootstrap framework styles with Ninja Forms validation.
 */
function ninjaValidation() {
	if (typeof Marionette !== 'undefined') {
		const ninjaValidationController = Marionette.Object.extend({
			initialize() {
				this.listenTo(
					Backbone.Radio.channel('fields'),
					'add:error',
					this.addError
				);

				this.listenTo(
					Backbone.Radio.channel('fields'),
					'remove:error',
					this.removeError
				);
			},

			addError(response) {
				const $el = $('#nf-field-' + response.id);
				$el.addClass('is-invalid');
			},

			removeError(response) {
				const $el = $('#nf-field-' + response.id);
				$el.removeClass('is-invalid');
			},
		});

		// Instantiate our validation controller, defined above.
		new ninjaValidationController();
	}
}

/*******************************************
 *  Custom Functions for the theme
 *******************************************/

/**
 * On Loads
 */
$(document).ready(function () {
	fouc();
	optimizeScroll();
	// anchorSmoothScrolling();
	ninjaValidation();
	// backToTop();
	// scrollMagicGsap();
	// revealOnScroll()
});
